import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.reverse.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

import "jquery.cookie";
import { uuid4 } from "@/common/uuid";
import { ImmortalDB } from "immortal-db";
var LOCALHOST = "localhost";
export function getCurrentRootDomain() {
  var host = location.host;
  if (host.indexOf(LOCALHOST) > -1) return;
  var hostWithoutPort = host.split(":")[0];
  var temp = hostWithoutPort.split(".").reverse();
  var root = "." + temp[1] + "." + temp[0];
  return root;
}
var deviceIdCookieName = "dvid";
var rootDomain = getCurrentRootDomain();

if (rootDomain) {
  document.domain = rootDomain.substr(1);
  var jqueryCookieDefaultOptions = {
    domain: rootDomain
  }; // tslint:disable-next-line: no-any

  $.cookie.defaults = jqueryCookieDefaultOptions;
}

export var deviceId = function () {
  return __awaiter(void 0, void 0, void 0, function () {
    var _deviceId, vid, vid;

    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4
          /*yield*/
          , ImmortalDB.get(deviceIdCookieName)];

        case 1:
          _deviceId = _a.sent();
          if (!!_deviceId) return [3
          /*break*/
          , 3];
          vid = uuid4();
          _deviceId = vid;
          return [4
          /*yield*/
          , ImmortalDB.set(deviceIdCookieName, vid)];

        case 2:
          _a.sent();

          return [3
          /*break*/
          , 5];

        case 3:
          if (!(!_deviceId.includes("-") || _deviceId.length < 30)) return [3
          /*break*/
          , 5];
          vid = uuid4();
          _deviceId = vid;
          return [4
          /*yield*/
          , ImmortalDB.set(deviceIdCookieName, vid)];

        case 4:
          _a.sent();

          return [2
          /*return*/
          , _deviceId];

        case 5:
          return [2
          /*return*/
          , _deviceId];
      }
    });
  });
}();