/* eslint-disable fht-eslint-rules/string-literal-validator */
import { assert } from "@/common/assert";
import { fhtCommonEnv } from "./common/fhtGlobalEnv";
import { qadd } from "./url_helpers";
var companyHost = fhtCommonEnv.Hosts.FhtWebCompanyRoot;
export function cmsHomeUrl(companyId, opts) {
  assert(companyId, "companyId falsy");
  return qadd(companyHost + ("/c" + companyId), opts);
}
export function cmsProductsUrl(companyId) {
  assert(companyId, "companyId falsy");
  return companyHost + ("/c" + companyId + "/product");
}
export function cmsPresetArticleUrl(companyId, presetPage) {
  assert(companyId, "companyId falsy");
  return companyHost + ("/c" + companyId + "/" + presetPage);
}
export function cmsNewsUrl(companyId) {
  assert(companyId, "companyId falsy");
  return companyHost + "/c" + companyId + "/news";
}
export function cmsAboutUsUrl(companyId) {
  assert(companyId, "companyId falsy");
  return companyHost + ("/c" + companyId + "/about.html");
}
export function cmsContactUsUrl(companyId) {
  assert(companyId, "companyId falsy");
  return companyHost + ("/c" + companyId + "/contact.html");
}
export function productDetailUrl(productId) {
  assert(productId, "productId falsy");
  return fhtCommonEnv.Hosts.FhtWebDetailRoot + ("/pro" + productId + ".html");
}
export function cmsArticleDetailUrl(companyId, articleId) {
  assert(companyId, "companyId falsy");
  assert(articleId, "articleId falsy");
  return companyHost + ("/c" + companyId + "/n" + articleId + ".html");
}
export function cmsArticleTypeUrl(companyId, articleTypeId) {
  assert(companyId, "companyId falsy");
  assert(articleTypeId, "articleTypeId falsy");
  return companyHost + ("/c" + companyId + "/n" + articleTypeId);
}