function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";

var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
      m = s && o[s],
      i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function next() {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};

import { fhtCommonEnv } from "@/common/fhtGlobalEnv";
import { assert } from "@/common/assert";
export function warn(message) {
  if (fhtCommonEnv.DEBUG) {
    // eslint-disable-next-line no-alert
    alert(message);
  } else {
    console.error(message);
  }
}
export function IsBoolean(value) {
  return typeof value === "boolean";
}
export function IsString(value) {
  return typeof value === "string";
}
export function IsNumber(value) {
  return typeof value === "number";
}
export function IsArray(value) {
  return Object.prototype.toString.call(value) === "[object Array]";
}
export function IsObject(value) {
  return _typeof(value) === "object";
}
export function IsObjectOrArray(value) {
  return IsObject(value) || IsArray(value);
}
export function unique(array) {
  return $.grep(array, function (el, index) {
    return index === $.inArray(el, array);
  });
}
export function stripHTML(dangerousText) {
  var container = document.createElement("div");
  var text = document.createTextNode(dangerousText);
  container.appendChild(text);
  return container.innerHTML; // innerHTML will be a xss safe string
}
export function ensureAttributesForSelector(selector, attrs) {
  $(selector).each(function (_index, el) {
    var e_1, _a;

    try {
      for (var attrs_1 = __values(attrs), attrs_1_1 = attrs_1.next(); !attrs_1_1.done; attrs_1_1 = attrs_1.next()) {
        var attr = attrs_1_1.value;
        var attributeValue = $(el).attr(attr);
        assert(attributeValue !== undefined, attr + " must exist for selector " + selector);
      }
    } catch (e_1_1) {
      e_1 = {
        error: e_1_1
      };
    } finally {
      try {
        if (attrs_1_1 && !attrs_1_1.done && (_a = attrs_1["return"])) _a.call(attrs_1);
      } finally {
        if (e_1) throw e_1.error;
      }
    }
  });
}