import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.index-of.js";
import { toISOString } from '@/apis/api/api';
/* eslint-disable fht-eslint-rules/string-literal-validator */
//AddtionallyRequired must be supplyed by users of url_gen.js

import { getActualHost } from "./AdditionallyRequired"; //add query parameter

export function qadd(url, additionalArgs) {
  if (!additionalArgs) return url;
  var args = []; // tslint:disable-next-line:forin

  for (var key in additionalArgs) {
    // tslint:disable-next-line:no-any
    var value = additionalArgs[key];
    args.push(key + "=" + encodeURIComponent(value.toString()));
  }

  var p = args.join("&");
  if (!p.length) return url;
  return url.indexOf("?") > -1 ? url + "&" + p : url + "?" + p;
}

function ec(value) {
  if (value instanceof Date) {
    return toISOString(value);
  } else if (typeof value === "string") {
    return encodeURIComponent(value);
  }

  return value;
}

var tr = function () {
  var shortenedHostMap = {
    _a65: "FhtWebUrlRoot",
    _2cc: "FhtWebCompanyRoot",
    _be2: "FhtWebMobileCompanyRoot",
    _bb0: "FhtWebTopicRoot",
    _540: "FhtWebDetailRoot",
    _546: "FhtWebMobileUrlRoot"
  };
  return function (url, t) {
    var h = shortenedHostMap["_" + t];
    if (!h) throw new Error("oops");
    var host = getActualHost(h);
    return host + url;
  };
}();

export var UrlHelperExtensions = {
  $Home: tr("/", "a65")
  /* FhtWebUrlRoot */
  ,
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $CmsHome: function $CmsHome(companyId, opts) {
    return qadd(tr("/c" + ec(companyId), "2cc")
    /* FhtWebCompanyRoot */
    , opts);
  },
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $CmsProductList: function $CmsProductList(companyId, opts) {
    return qadd(tr("/c" + ec(companyId) + "/product", "2cc")
    /* FhtWebCompanyRoot */
    , opts);
  },
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $ArticleType: function $ArticleType(companyId, articleTypeId, opts) {
    return qadd(tr("/c" + ec(companyId) + "/n" + ec(articleTypeId), "2cc")
    /* FhtWebCompanyRoot */
    , opts);
  },
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $UserCenterMenuUrl: function $UserCenterMenuUrl(menu, opts) {
    return qadd(tr("/Account/Center#/UserCenter/" + ec(menu), "a65")
    /* FhtWebUrlRoot */
    , opts);
  },
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $EnterpriseCenterMenuUrl: function $EnterpriseCenterMenuUrl(menu, opts) {
    return qadd(tr("/Account/Center#/CompanyCenter/" + ec(menu), "a65")
    /* FhtWebUrlRoot */
    , opts);
  },
  $EditTemplateUrl: tr("/Account/EditTemplate", "a65")
  /* FhtWebUrlRoot */
  ,
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $CompanyMobileUrl: function $CompanyMobileUrl(companyId, opts) {
    return qadd(tr("/c" + ec(companyId), "be2")
    /* FhtWebMobileCompanyRoot */
    , opts);
  },
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $ArticleDetailUrl: function $ArticleDetailUrl(articleId, companyId, opts) {
    return qadd(tr("/c" + ec(companyId) + "/n" + ec(articleId) + ".html", "2cc")
    /* FhtWebCompanyRoot */
    , opts);
  },
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $ArticleDetailMobileUrl: function $ArticleDetailMobileUrl(articleId, companyId, opts) {
    return qadd(tr("/c" + ec(companyId) + "/n" + ec(articleId) + ".html", "be2")
    /* FhtWebMobileCompanyRoot */
    , opts);
  },
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $TopicPostDetailUrl: function $TopicPostDetailUrl(topicPostId, opts) {
    return qadd(tr("/fuwu/" + ec(topicPostId) + ".html", "bb0")
    /* FhtWebTopicRoot */
    , opts);
  },
  $TopicPostSearchUrl: tr("/tsearch", "bb0")
  /* FhtWebTopicRoot */
  ,
  $TopicPostListUrl: tr("/fuwu", "bb0")
  /* FhtWebTopicRoot */
  ,
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $TopicPostListByPinYinUrl: function $TopicPostListByPinYinUrl(topicType, opts) {
    return qadd(tr("/fuwu/" + ec(topicType), "bb0")
    /* FhtWebTopicRoot */
    , opts);
  },
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $ProductDetail: function $ProductDetail(productId, opts) {
    return qadd(tr("/pro" + ec(productId) + ".html", "540")
    /* FhtWebDetailRoot */
    , opts);
  },
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $ProductMobileDetail: function $ProductMobileDetail(productId, opts) {
    return qadd(tr("/pro" + ec(productId) + ".html", "546")
    /* FhtWebMobileUrlRoot */
    , opts);
  },
  $ProductListMobileUrl: tr("/list", "546")
  /* FhtWebMobileUrlRoot */
  ,
  $ProductListUrl: tr("/list", "a65")
  /* FhtWebUrlRoot */
  ,
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $ProductListUrl2: function $ProductListUrl2(systemCategoryId, opts) {
    return qadd(tr("/list/" + ec(systemCategoryId), "a65")
    /* FhtWebUrlRoot */
    , opts);
  },
  $CompanySearchUrl: tr("/csearch", "a65")
  /* FhtWebUrlRoot */
  ,
  $OfficialWebsiteUrl: tr("/officialsite/home.html", "a65")
  /* FhtWebUrlRoot */
  ,
  $UserSearchUrl: tr("/usearch", "a65")
  /* FhtWebUrlRoot */
  ,
  $ProductSearchUrl: tr("/psearch", "a65")
  /* FhtWebUrlRoot */
  ,
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $ProductSearchUrl2: function $ProductSearchUrl2(q, opts) {
    return qadd(tr("/psearch?q=" + ec(q), "a65")
    /* FhtWebUrlRoot */
    , opts);
  },
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $ProfileUrl: function $ProfileUrl(userId, opts) {
    return qadd(tr("/user/" + ec(userId) + ".html", "a65")
    /* FhtWebUrlRoot */
    , opts);
  },
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $MembershipApplicationUrl: function $MembershipApplicationUrl(companyId, opts) {
    return qadd(tr("/Company/MembershipApplication?companyId=" + ec(companyId), "a65")
    /* FhtWebUrlRoot */
    , opts);
  },
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $CompanyCertificateUrl: function $CompanyCertificateUrl(companyId, opts) {
    return qadd(tr("/certificate/" + ec(companyId) + ".html", "a65")
    /* FhtWebUrlRoot */
    , opts);
  },
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $OfficialSiteProductUrl: function $OfficialSiteProductUrl(selectedTabId, opts) {
    return qadd(tr("/officialsite/" + ec(selectedTabId) + ".html", "a65")
    /* FhtWebUrlRoot */
    , opts);
  },
  LoggedInUserIndexPartialUrl: "/Account/LoggedInUserIndexPartial"
  /* null */
  ,
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $LoginUrl: function $LoginUrl(returnUrl, opts) {
    return qadd(tr("/Account/Login?returnUrl=" + ec(returnUrl), "a65")
    /* FhtWebUrlRoot */
    , opts);
  },
  $RegisterUrl: tr("/Account/Register", "a65")
  /* FhtWebUrlRoot */
  ,
  $Fht1LoggedInUserIndexPartialUrl: tr("/Account/Fht1LoggedInUserIndexPartial", "a65")
  /* FhtWebUrlRoot */
  ,
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  CompanyDetailModalUrl: function CompanyDetailModalUrl(companyId, opts) {
    return qadd("/Company/CompanyDetailModal?companyId=" + ec(companyId)
    /* null */
    , opts);
  },
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  UserHoverCardUrl: function UserHoverCardUrl(userId, opts) {
    return qadd("/User/UserHoverCard?userId=" + ec(userId)
    /* null */
    , opts);
  },
  $BrowserUnsupportedUrl: tr("/Exception/BrowserUnsupported", "a65")
  /* FhtWebUrlRoot */
  ,
  $XdLocalStorageUrl: tr("/Home/Xdlocalstorage", "a65")
  /* FhtWebUrlRoot */
  ,
  FileUploadUrl: "/Upload/FileUpload"
  /* null */
  ,
  SearchArticleIndexUrl: "/hot/xw"
  /* null */
  ,
  SearchCompanyIndexUrl: "/company"
  /* null */
  ,
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $SystemCategoryId_: function $SystemCategoryId_(systemCategoryId, companyId, opts) {
    return qadd(tr("/c" + ec(companyId) + "/s" + ec(systemCategoryId), "2cc")
    /* FhtWebCompanyRoot */
    , opts);
  },
  //标记为$前缀的方法里面的url包含了domain/origin，此举是为了更加明显的检查到前端项目里面CORS相关的ajax请求
  $CustomCategoryId_: function $CustomCategoryId_(customCategoryId, companyId, opts) {
    return qadd(tr("/c" + ec(companyId) + "/ct" + ec(customCategoryId), "2cc")
    /* FhtWebCompanyRoot */
    , opts);
  }
};