import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/web.timers.js";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

import { assert } from "@/common/assert";
import "xdlocalstorage";
import { fhtCommonEnv, IS_DEV } from "@/common/fhtGlobalEnv";

var FhtStorage =
/** @class */
function () {
  function FhtStorage() {
    assert(fhtCommonEnv.Hosts.FhtWebUrlRoot, "no FhtWebUrlRoot");
    var LOCALHOST = "localhost";
    var host = location.host.indexOf(LOCALHOST) > -1 ? "" : fhtCommonEnv.Hosts.FhtWebUrlRoot; // eslint-disable-next-line fht-eslint-rules/string-literal-validator

    var iframeUrl = host + "/Home/XdLocalStorage";
    var xd = window.xdLocalStorage;
    assert(xd, "xd not found");
    this._sp = new Promise(function (res, rej) {
      xd.init({
        iframeUrl: iframeUrl,
        initCallback: function initCallback() {
          console.log("xd called back");
          res(xd);
        }
      });
      setTimeout(function () {
        return rej(new Error("xd init timed out"));
      }, 30000);
    });
  }

  FhtStorage.prototype.getItem = function (key) {
    return __awaiter(this, void 0, Promise, function () {
      var s;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this._sp];

          case 1:
            s = _a.sent();
            return [4
            /*yield*/
            , new Promise(function (res, rej) {
              s.getItem(key, function (d) {
                assert(d.key, "no key");
                res(d.value);
              });
            })];

          case 2:
            return [2
            /*return*/
            , _a.sent()];
        }
      });
    });
  };

  FhtStorage.prototype.setItem = function (key, value) {
    return __awaiter(this, void 0, Promise, function () {
      var s;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this._sp];

          case 1:
            s = _a.sent();
            return [4
            /*yield*/
            , new Promise(function (res, rej) {
              s.setItem(key, value, function (d) {
                assert(d.key, "no key");

                if (d.success) {
                  res(d.value);
                } else {
                  rej(d.value);
                }
              });
            })];

          case 2:
            return [2
            /*return*/
            , _a.sent()];
        }
      });
    });
  };

  FhtStorage.prototype.removeItem = function (key) {
    return __awaiter(this, void 0, Promise, function () {
      var s, xd;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this._sp];

          case 1:
            s = _a.sent();
            xd = window.xdLocalStorage;
            assert(xd, "xd not found");
            return [2
            /*return*/
            , new Promise(function (res) {
              xd.removeItem(key, function (d) {
                return res();
              });
            })];
        }
      });
    });
  };

  return FhtStorage;
}();

export var fhtStorage = function () {
  if (IS_DEV) {
    var localStorageVersion = {
      getItem: function getItem(key) {
        return Promise.resolve(localStorage.getItem(key) || undefined);
      },
      setItem: function setItem(key, value) {
        localStorage.setItem(key, value);
        return Promise.resolve(value);
      },
      removeItem: function removeItem(key) {
        return Promise.resolve(localStorage.removeItem(key));
      }
    };
    return localStorageVersion;
  } else {
    /** Cross domain local storage. */
    return new FhtStorage();
  }
}();