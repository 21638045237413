import "core-js/modules/es.object.set-prototype-of.js";
import "core-js/modules/es.object.create.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");

    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}(); // FHT Swagger jQuery client V2

/* eslint-disable */


import { BaseApi } from "./api";

var CompanyApiApi =
/** @class */
function (_super) {
  __extends(CompanyApiApi, _super);

  function CompanyApiApi() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.companyApiActiveCompanyAsync = function (p) {
      var localVarPath = '/api/companies/activeCompanies';

      _this.r(p, ["args.top"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.companyApiAutoCompleteCompany = function (p) {
      var localVarPath = '/api/companies/_autoComplete';

      _this.r(p, ["term"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.companyApiCancelCompany = function () {
      var localVarPath = '/api/companies/_cancel';
      var bodyDict = undefined;
      return _this.ajax("POST", localVarPath, {}, bodyDict);
    };

    _this.companyApiChangeCompany = function (p) {
      var localVarPath = '/api/companies/_edit';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.companyApiChangeCompanyAboutUs = function (p) {
      var localVarPath = '/api/companies/_changecompanyaboutus';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.companyApiChangeCompanyContractInfo = function (p) {
      var localVarPath = '/api/companies/_changecompanycontractinfo';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.companyApiChangeCompanyGeoLocation = function (p) {
      var localVarPath = '/api/companies/_changecompanygeolocation';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.companyApiChangeCompanyLogoUrl = function (p) {
      var localVarPath = '/api/companies/_changecompanylogourl';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.companyApiChangeCompanyNameApplyAsync = function (p) {
      var localVarPath = '/api/companies/_changecompanynameapply';

      _this.r(p, ["companyName"]);

      var bodyDict = undefined;
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.companyApiChangeCompanySeo = function (p) {
      var localVarPath = '/api/companies/_changecompanyseo';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.companyApiChangeMobileTemplate = function (p) {
      var localVarPath = '/api/companies/_changemobiletemplate';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.companyApiCheckIfAllowedToUseCompanyName = function (p) {
      var localVarPath = '/api/companies/_checkIfAllowedToUseCompanyName';

      _this.r(p, ["companyName"]);

      var bodyDict = undefined;
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.companyApiCompanyRegionSite = function (p) {
      var localVarPath = '/api/companies/_setRegionSite';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.companyApiCompanyRegistrationSearchAsync = function (p) {
      var localVarPath = '/api/companies/_searchForRegistration';

      _this.r(p, []);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.companyApiCreateCompany = function (p) {
      var localVarPath = '/api/companies/_create';

      _this.r(p, ["company"]);

      var bodyDict = p["company"];
      var _p = p;
      delete _p["company"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.companyApiDisableShopsAsync = function () {
      var localVarPath = '/api/companies/_disableShops';
      var bodyDict = undefined;
      return _this.ajax("POST", localVarPath, {}, bodyDict);
    };

    _this.companyApiEnableShopsAsync = function () {
      var localVarPath = '/api/companies/_enableShops';
      var bodyDict = undefined;
      return _this.ajax("POST", localVarPath, {}, bodyDict);
    };

    _this.companyApiExamineSite = function () {
      var localVarPath = '/api/companies/_examinesite';
      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, {}, bodyDict);
    };

    _this.companyApiGetCompany = function (p) {
      var localVarPath = '/api/companies/{companyId}'.replace('{' + 'companyId' + '}', String(p["companyId"]));

      _this.r(p, ["companyId"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.companyApiGetCompanyForModify = function () {
      var localVarPath = '/api/companies/_get';
      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, {}, bodyDict);
    };

    _this.companyApiGetCompanySeo = function () {
      var localVarPath = '/api/companies/_getcompanyseo';
      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, {}, bodyDict);
    };

    _this.companyApiGetMyCompany = function () {
      var localVarPath = '/api/companies/mycompany';
      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, {}, bodyDict);
    };

    _this.companyApiGetNearbyCompanies = function (p) {
      var localVarPath = '/api/companies/nearbyCompanies';

      _this.r(p, ["args.currPage", "args.limit"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.companyApiGetNewlyRegisteredEnterprise = function (p) {
      var localVarPath = '/api/companies/newlyRegisteredCompanies';

      _this.r(p, ["args.top"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.companyApiGetPhoneNumber = function (p) {
      var localVarPath = '/api/companies/_getPhoneNumber';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.companyApiGetStatCompanyIdsAsync = function (p) {
      var localVarPath = '/api/companies/_getstatcompanyids';

      _this.r(p, ["args.limit"]);

      var bodyDict = undefined;
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.companyApiSetRegionSiteShow = function (p) {
      var localVarPath = '/api/companies/_setRegionSiteShow';

      _this.r(p, ["regionSiteEnabled"]);

      var bodyDict = undefined;
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.companyApiSetShowMemberInShops = function (p) {
      var localVarPath = '/api/companies/_setshowmemberinshops';

      _this.r(p, ["isShow"]);

      var bodyDict = undefined;
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.companyApiToggleShopStatus = function (p) {
      var localVarPath = '/api/companies/shopStatus/_toggle/{companyId}'.replace('{' + 'companyId' + '}', String(p["companyId"]));

      _this.r(p, ["companyId"]);

      var bodyDict = undefined;
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    return _this;
  }

  return CompanyApiApi;
}(BaseApi);

export { CompanyApiApi };