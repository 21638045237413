import "core-js/modules/es.object.set-prototype-of.js";
import "core-js/modules/es.object.create.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");

    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}(); // FHT Swagger jQuery client V2

/* eslint-disable */


import { BaseApi } from "./api";

var AccountApiApi =
/** @class */
function (_super) {
  __extends(AccountApiApi, _super);

  function AccountApiApi() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.accountApiApplyings = function () {
      var localVarPath = '/api/applyings';
      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, {}, bodyDict);
    };

    _this.accountApiBasicAccountInfo = function (p) {
      var localVarPath = '/api/accounts/basicAccount';

      _this.r(p, ["hash.authHash"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.accountApiCancel = function (p) {
      var localVarPath = '/api/accounts/_cancel';

      _this.r(p, ["smsCode"]);

      var bodyDict = undefined;
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.accountApiChangePasswordAsync = function (p) {
      var localVarPath = '/api/accounts/_changepassword';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.accountApiChangePhoneNumber = function (p) {
      var localVarPath = '/api/accounts/_changephonenumber';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.accountApiCheckIfAccountExistsAsync = function (p) {
      var localVarPath = '/api/accounts/_checkIfAccountExists';

      _this.r(p, ["userName"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.accountApiCreateFht1CompanyUser = function (p) {
      var localVarPath = '/api/fht1CompanyUsers/_create';

      _this.r(p, ["inputModel"]);

      var bodyDict = p["inputModel"];
      var _p = p;
      delete _p["inputModel"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.accountApiCreateVerifyCode = function (p) {
      var localVarPath = '/api/accounts/_createVerifyCode';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.accountApiEditProfileAsync = function (p) {
      var localVarPath = '/api/accounts/_editProfile';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.accountApiFindPassword = function (p) {
      var localVarPath = '/api/accounts/_findPassword';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.accountApiGenLoginUuid = function () {
      var localVarPath = '/api/accounts/_genLoginUuid';
      var bodyDict = undefined;
      return _this.ajax("POST", localVarPath, {}, bodyDict);
    };

    _this.accountApiGetFht1CompanyUserByFht1CompanyId = function (p) {
      var localVarPath = '/api/fht1CompanyUsers/{fht1CompanyId}'.replace('{' + 'fht1CompanyId' + '}', String(p["fht1CompanyId"]));

      _this.r(p, ["fht1CompanyId"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.accountApiGetFht1CompanyUserByFht2UserName = function (p) {
      var localVarPath = '/api/fht1CompanyUsers/getForFht2UserName';

      _this.r(p, ["fht2UserName"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.accountApiGetMyProfile = function (p) {
      var localVarPath = '/api/accounts/myProfile';

      _this.r(p, ["hash.authHash"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.accountApiGetProfileForEdit = function () {
      var localVarPath = '/api/accounts/_getProfileForEdit';
      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, {}, bodyDict);
    };

    _this.accountApiInvitedCompany = function (p) {
      var localVarPath = '/api/invitedcompanies';

      _this.r(p, ["args.currPage", "args.limit"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.accountApiLogOff = function () {
      var localVarPath = '/api/accounts/_logOff';
      var bodyDict = undefined;
      return _this.ajax("POST", localVarPath, {}, bodyDict);
    };

    _this.accountApiLoginAsync = function (p) {
      var localVarPath = '/api/accounts/_login';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.accountApiOAuthLoginAsync = function (p) {
      var localVarPath = '/api/accounts/_oauthlogin';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.accountApiPostToFht1UrlAsync = function () {
      var localVarPath = '/api/accounts/_toOldFhtUrl';
      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, {}, bodyDict);
    };

    _this.accountApiRefreshQrCodeAsync = function (p) {
      var localVarPath = '/api/accounts/_refreshQrCode';

      _this.r(p, ["uuid"]);

      var bodyDict = undefined;
      return _this.ajax("GET", localVarPath, p, bodyDict);
    };

    _this.accountApiRegister = function (p) {
      var localVarPath = '/api/accounts/_register';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.accountApiResetAccount = function (p) {
      var localVarPath = '/api/accounts/_resetAccount';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.accountApiSmsCodeLoginAsync = function (p) {
      var localVarPath = '/api/accounts/_smsCodeLogin';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.accountApiUpdateFht1CompanyUser = function (p) {
      var localVarPath = '/api/fht1CompanyUsers/_update';

      _this.r(p, ["inputModel"]);

      var bodyDict = p["inputModel"];
      var _p = p;
      delete _p["inputModel"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    _this.accountApiVerifyAccountAndSmsCode = function (p) {
      var localVarPath = '/api/accounts/_verifyAccountAndSmsCode';

      _this.r(p, ["model"]);

      var bodyDict = p["model"];
      var _p = p;
      delete _p["model"];
      return _this.ajax("POST", localVarPath, p, bodyDict);
    };

    return _this;
  }

  return AccountApiApi;
}(BaseApi);

export { AccountApiApi };