import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

import { assert } from "@/common/assert";
import { fhtCommonEnv } from "@/common/fhtGlobalEnv";
export function getActualHost(hostTag) {
  var hosts = fhtCommonEnv.Hosts;
  var h = hosts[hostTag];
  assert(_typeof(h) !== undefined, "failed to find hostTag: " + hostTag);
  return h;
}