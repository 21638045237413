import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

import { assert } from "@/common/assert";

function _optionalVal($el, key) {
  assert($el, "$el is null");
  var val = $el.data(key);
  if (typeof val === "undefined") return;
  return val;
}

var jqueryDataUtil = {
  optionalString: function optionalString($el, key) {
    var val = _optionalVal($el, key);

    if (typeof val === "undefined") return;
    assert(typeof val === "string", "val not string, key: " + key + ", type: " + _typeof(key));
    return val;
  },
  requiredString: function requiredString($el, key) {
    var val = jqueryDataUtil.optionalString($el, key);
    assert(val, key + " is required");
    return val;
  },
  optionalNumber: function optionalNumber($el, key) {
    var val = _optionalVal($el, key);

    if (typeof val === "undefined") return;
    var n = Number(val);
    assert(typeof n === "number" && !isNaN(n), key + " is not a valid number");
    return n;
  },
  requiredNumber: function requiredNumber($el, key) {
    var val = jqueryDataUtil.optionalNumber($el, key);
    assert(typeof val === "number", key + " is a required number");
    return val;
  },
  optionalBoolean: function optionalBoolean($el, key) {
    var val = _optionalVal($el, key);

    if (typeof val === "undefined") return;
    assert(typeof val === "boolean", key + " is required to be \"true\" or \"false\", but is " + val);
    return val;
  },
  requiredBoolean: function requiredBoolean($el, key) {
    var val = jqueryDataUtil.optionalBoolean($el, key);
    assert(typeof val === "boolean", key + " is required");
    return val;
  }
};
export default jqueryDataUtil;