import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.regexp.to-string.js";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

import { retriedWaitFor } from "./asyncUtils";
import * as Sentry from "@sentry/browser";
import { assert } from "@/common/assert";
import { IS_CMS_WEB } from "@/common/fhtGlobalEnv";
/**
 * proxy vue里面在window下面埋入的 basicAccountInfoManager
 */

var BasicAccountInfoManagerProxy =
/** @class */
function () {
  function BasicAccountInfoManagerProxy() {
    this._isAvailable = !IS_CMS_WEB;
  }

  BasicAccountInfoManagerProxy.prototype.getRealBasicAccountInfoManager = function () {
    return __awaiter(this, void 0, Promise, function () {
      var inst;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!!this._realManagerInstance) return [3
            /*break*/
            , 2];
            return [4
            /*yield*/
            , retriedWaitFor(function () {
              return window._BASIC_ACCOUNT_INFO_MANAGER_;
            }, "_BASIC_ACCOUNT_INFO_MANAGER_", 100, 10000, "Make sure #vue-vueShared is in page")];

          case 1:
            inst = _a.sent();
            assert(inst.getBasicAccountInfo, "gotta have: " + "getBasicAccountInfo");
            assert(inst.onAccountInfoUpdated, "gotta have: " + "onAccountInfoUpdated");
            assert(inst.proceedAfterLogin, "gotta have: " + "proceedAfterLogin");
            this._realManagerInstance = inst;
            _a.label = 2;

          case 2:
            return [2
            /*return*/
            , this._realManagerInstance];
        }
      });
    });
  };

  BasicAccountInfoManagerProxy.prototype.onAccountInfoUpdated = function (subscriber) {
    if (!this._isAvailable) return;
    this.getRealBasicAccountInfoManager().then(function (m) {
      m.onAccountInfoUpdated(subscriber);
    });
  };

  BasicAccountInfoManagerProxy.prototype.getBasicAccountInfo = function () {
    return __awaiter(this, void 0, Promise, function () {
      var m, info;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!this._isAvailable) return [2
            /*return*/
            , {}];
            return [4
            /*yield*/
            , this.getRealBasicAccountInfoManager()];

          case 1:
            m = _a.sent();
            return [4
            /*yield*/
            , m.getBasicAccountInfo()];

          case 2:
            info = _a.sent();
            Sentry.setUser({
              id: (info.UserId || "").toString()
            });
            return [2
            /*return*/
            , info];
        }
      });
    });
  };

  BasicAccountInfoManagerProxy.prototype.proceedAfterLogin = function () {
    return __awaiter(this, void 0, Promise, function () {
      var m;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!this._isAvailable) {
              return [2
              /*return*/
              , new Promise(function (res, rej) {//never resolves
              })];
            }

            return [4
            /*yield*/
            , this.getRealBasicAccountInfoManager()];

          case 1:
            m = _a.sent();
            return [4
            /*yield*/
            , m.proceedAfterLogin()];

          case 2:
            return [2
            /*return*/
            , _a.sent()];
        }
      });
    });
  };

  return BasicAccountInfoManagerProxy;
}();

export var basicAccountInfoManager = new BasicAccountInfoManagerProxy();