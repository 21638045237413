import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//courtesy of https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
export function uuid4() {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp

  /* eslint-disable-next-line compat/compat  */

  var d2 = performance && performance.now && performance.now() * 1000 || 0; //Time in microseconds since page-load or 0 if unsupported

  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16

    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }

    return (c === "x" ? r : r & 0x3 | 0x8).toString(16);
  });
}