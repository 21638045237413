import "core-js/modules/web.timers.js";
import "./index.scss";
import swal from "sweetalert";
export function simpleAlert(text) {
  return swal({
    title: "",
    text: text,
    className: "fht-alert--warning",
    timer: 1500,
    buttons: [false]
  });
}
/**
 * 用于操作成功的提示
 */

export function successAlert(text) {
  return swal({
    title: "",
    text: text,
    className: "fht-alert--success",
    timer: 1500,
    buttons: [false]
  });
} // tslint:disable-next-line: no-any

export function _fallBackAlertWithCallback(text, callback) {
  // eslint-disable-next-line no-alert
  alert(text);
  setTimeout(callback, 0);
}
/**
 * 不带有取消按钮的功能性提示框
 */

export function functionAlert(text, callback) {
  return swal({
    title: "提示",
    text: text,
    className: "fht-alert",
    buttons: {
      confirm: {
        text: "确定",
        className: "btn btn--config"
      }
    }
  }).then(callback);
}
/**
 * 带有取消按钮的功能性的提示框
 */

export function confirmAlert(text, callback) {
  return swal({
    title: "提示",
    text: text,
    className: "fht-alert",
    dangerMode: true,
    buttons: {
      confirm: {
        text: "确定",
        className: "btn fht-alert__config"
      },
      cancel: {
        text: "取消",
        visible: true,
        className: "btn fht-alert__cancel"
      }
    } // tslint:disable-next-line: no-any

  }).then(callback);
}
/**
 * 带有取消按钮的功能性的提示框2
 */

export function setupAlert(text, callback) {
  return swal({
    title: "提示",
    text: text,
    className: "fht-alert",
    dangerMode: true,
    buttons: {
      confirm: {
        text: "设置",
        className: "btn btn--config"
      },
      cancel: {
        text: "取消",
        visible: true,
        className: "btn btn--cancel"
      }
    } // tslint:disable-next-line: no-any

  }).then(callback);
}