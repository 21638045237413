import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.date.to-string.js";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

import { assert } from "@/common/assert"; // type Promisify<T> = T extends Promise<infer TResult> ? T : Promise<T>;

export function wait(ms) {
  return new Promise(function (res) {
    setTimeout(res, ms);
  });
}
export function asyncMapLimit(src, limit, mapper) {
  assert(limit > 0, "limit is not positive");
  var deferreds = src.map(function () {
    return $.Deferred();
  });
  var index = 0;
  var pendingCount = 0;

  function schedule() {
    var _loop_1 = function _loop_1() {
      var arg = src[index];
      pendingCount++;
      var result = mapper(arg);
      var deferred = deferreds[index];
      assert(deferred, "deferred is falsy??");
      result.done(function (d) {
        return deferred.resolve(d);
      }).fail(function (f) {
        return deferred.reject(f);
      }).always(function () {
        pendingCount--;
        schedule();
      });
      index++;
    };

    while (index < src.length && pendingCount < limit) {
      _loop_1();
    }
  }

  schedule();
  return deferreds;
}
export var promiseOrdered = function () {
  var $doc = $(document);

  function _promiseOrdered(queueName) {
    if (queueName === void 0) {
      queueName = "_DQ_";
    }

    return function ($src, tag) {
      //see also https://api.jquery.com/queue/
      var $d = $.Deferred();
      if (tag) console.log("QUEUE: " + tag);
      $doc.queue(queueName, function (next) {
        $src.done(function (d) {
          return $d.resolve(d);
        }).fail(function (f) {
          return $d.reject(f);
        }).always(function () {
          if (tag) {
            console.log("RESOLVED: " + tag);
          }

          next();
        });
      });

      if ($doc.queue(queueName).length === 1) {
        //in case of callers repeatedly call promiseOrdered in synchronous manner, we should not dequeue immediately.
        setTimeout(function () {
          $doc.dequeue(queueName);
        }, 0);
      }

      return $d.promise();
    };
  }

  var _defaultFunc = _promiseOrdered();

  function wrapper(firstArg, tag) {
    if (typeof firstArg === "string") {
      return _promiseOrdered(firstArg);
    }

    return _defaultFunc(firstArg, tag);
  }

  return wrapper;
}();
export function retriedWaitFor(evaluator, tag, checkIntervalMs, timeoutMs, additionalMessage) {
  if (checkIntervalMs === void 0) {
    checkIntervalMs = 100;
  }

  if (timeoutMs === void 0) {
    timeoutMs = 5000;
  }

  if (additionalMessage === void 0) {
    additionalMessage = "";
  }

  return new Promise(function (res, rej) {
    var accumulatedMs = 0;
    var value = evaluator();

    if (value) {
      res(value);
    } else {
      var tid_1 = setInterval(function () {
        accumulatedMs += checkIntervalMs;
        var valueAgain = evaluator();

        if (valueAgain) {
          clearInterval(tid_1);
          res(valueAgain);
        } else {
          var timedout = accumulatedMs > timeoutMs;

          if (timedout) {
            clearInterval(tid_1);
            rej(new Error("wait for evaluator " + tag + " timed out after " + timeoutMs + " ms: " + additionalMessage));
          }
        }
      }, checkIntervalMs);
    }
  });
}
export function retriedWaitForPromise(evaluator, checkIntervalMs, timeoutMs) {
  if (checkIntervalMs === void 0) {
    checkIntervalMs = 100;
  }

  if (timeoutMs === void 0) {
    timeoutMs = 5000;
  }

  return __awaiter(this, void 0, Promise, function () {
    var startTime, value;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          startTime = new Date().getTime();
          _a.label = 1;

        case 1:
          if (!(new Date().getTime() - startTime < timeoutMs)) return [3
          /*break*/
          , 4];
          return [4
          /*yield*/
          , evaluator()];

        case 2:
          value = _a.sent();
          if (value) return [2
          /*return*/
          , value];
          return [4
          /*yield*/
          , wait(checkIntervalMs)];

        case 3:
          _a.sent();

          return [3
          /*break*/
          , 1];

        case 4:
          throw new Error("wait for evaluator promise timed out after " + timeoutMs + " ms");
      }
    });
  });
}
export function fromJQueryPromise($d) {
  return new Promise(function (res, rej) {
    $d.done(res).fail(rej);
  });
}
export function toJQueryPromise(p) {
  var $d = $.Deferred();
  p.then(function (d) {
    return $d.resolve(d);
  })["catch"](function (e) {
    return $d.reject(e);
  });
  return $d;
}
export function debounce(wait_ms, func) {
  var that = this;
  var timerId; // tslint:disable-next-line: no-any

  var prevReject;

  function isJQueryPromise(p) {
    var result = p;
    return !!(result && result.done && typeof result.done === "function");
  }

  function wrapper() {
    // eslint-disable-next-line prefer-rest-params
    var args = arguments;

    if (timerId) {
      if (prevReject) {
        var warnMessage = "cancelled due to debouncing";
        prevReject(warnMessage);
      }

      clearTimeout(timerId);
    }

    var $d = $.Deferred();

    prevReject = function prevReject(error) {
      return $d.reject(error);
    };

    timerId = setTimeout(function () {
      try {
        // tslint:disable-next-line: no-any
        var result = func.apply(that, args);

        if (isJQueryPromise(result)) {
          var pr = result;
          pr.done(function (d) {
            return $d.resolve(d);
          }).fail(function (f) {
            return $d.reject(f);
          });
        } else {
          $d.resolve(result);
        }
      } catch (error) {
        $d.reject(error);
      }
    }, wait_ms);
    return $d.promise();
  } // tslint:disable-next-line: no-any


  return wrapper;
}